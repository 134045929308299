import React, { useEffect, useState } from "react";
import CashfreeIcon from '../../assets/cashfree.png'
import { useLocation } from "react-router-dom";
import { redirectToCheckout } from "../../service/api";
import { load } from "@cashfreepayments/cashfree-js";
import Breadcrumb from "../../components/Breadcrumbs/Breadcrumb";
import Loader from "../../common/Loader/Loader";


const CheckoutPage = () => {
    const location = useLocation();
    const [totalPrice, setTotalPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cashfree, setCashfree] = useState(null)

    const [formData, setFormData] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
    });
    const { plan } = location.state || {};
    useEffect(() => {
        setTotalPrice(plan.price)
    }, [plan])

    const generateOrderId = () => {
        if (!formData.email) {
            alert("Please enter a valid email address to generate");
            return;
        }

        const now = new Date();
        const formattedDateTime = `${now.getFullYear()}${(now.getMonth() + 1)
            .toString()
            .padStart(2, "0")}${now
                .getDate()
                .toString()
                .padStart(2, "0")}${now
                    .getHours()
                    .toString()
                    .padStart(2, "0")}${now
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}${now
                            .getSeconds()
                            .toString()
                            .padStart(2, "0")}`;

        const emailPart = formData.email.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters
        const newOrderId = `${emailPart}_${formattedDateTime}`;
        return newOrderId;
    };
    const handleOrder = async () => {
        if (
            !formData.firstName.trim() ||
            !formData.lastName.trim() ||
            !formData.email.trim() ||
            !formData.phoneNumber.trim()
        ) {
            alert("Please fill out all the fields to proceed.");
            return;
        }
    
        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            alert("Please enter a valid email address.");
            return;
        }
    
        // Validate phone number (10 digits, no special characters)
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.phoneNumber)) {
            alert("Please enter a valid 10-digit phone number.");
            return;
        }

        const pricing_detail = {
            validMonths: plan.validMonths,
            pricingKey: plan.pricingKey,
        };
        const payload = {
            "order_amount": totalPrice,
            "order_currency": "INR",
            "order_id": generateOrderId(),
            "order_tags": pricing_detail,
            "customer_details": {
                "customer_id": formData.email.replace(/[^a-zA-Z0-9]/g, ""),
                "customer_phone": formData.phoneNumber,
                "customer_name": formData.firstName + " " + formData.middleName + " " + formData.lastName,
                "customer_email": formData.email,
            },
            "order_meta": {
                "return_url": "https://thequickfill.com/",
            },

        };
        setLoading(true);
        const paymentSessionId = await redirectToCheckout(payload)
        doPayment(paymentSessionId)

    }

    useEffect(() => {
        var initializeSDK = async function () {
            const temp_cashfree = await load({
                mode: "production"
            });
            setCashfree(temp_cashfree)
        }
        initializeSDK();

    }, [])


    const doPayment = async (paymentSessionId: any) => {
        let checkoutOptions = {
            paymentSessionId: paymentSessionId,
            redirectTarget: "_self",
        };
        //@ts-ignore
        cashfree && cashfree.checkout(checkoutOptions);
        setLoading(false)

    };




    // Handle input changes
    const handleChange = (e: any) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    if (loading) return <Loader />
    return (
        <>
            <Breadcrumb pageName="Checkout" />
            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <div className="col-span-12 rounded-sm border border-stroke bg-white px-2 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-6">
                    <div className="flex flex-col gap-7.5 p-1 sm:p-1 xl:p-1">
                        <form action="#">
                            <div className="p-2 flex flex-col gap-4 grid grid-cols-12">
                                <div className="w-full col-span-12 xl:col-span-6">
                                    <label htmlFor="firstName" className="mb-2.5 block text-black font-medium dark:text-white">First name</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        placeholder="Enter your first name"
                                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-2 text-black font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="w-full col-span-12 xl:col-span-6">
                                    <label className="mb-2.5 block text-black font-medium dark:text-white">Middle name</label>
                                    <input
                                        type="text"
                                        id="middleName"
                                        placeholder="Enter your middle name"
                                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-2 text-black font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                        value={formData.middleName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="w-full col-span-12 xl:col-span-6">
                                    <label className="mb-2.5 block text-black font-medium dark:text-white">Last name</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        placeholder="Enter your last name"
                                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-2 text-black font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="w-full col-span-12 xl:col-span-6">
                                    <label className="mb-2.5 block text-black font-medium dark:text-white">Email</label>
                                    <input
                                        type="text"
                                        id="email"
                                        placeholder="Enter your email addrress"
                                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-2 text-black font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>


                                <div className="w-full col-span-12 xl:col-span-6">
                                    <label className="mb-2.5 block text-black font-medium dark:text-white">Phone Number</label>
                                    <input
                                        type="text"
                                        id="phoneNumber"
                                        placeholder="Enter your phone number"
                                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-2 text-black font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>


                        </form>

                    </div>
                </div >


                <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-6">
                    <div className="flex flex-col gap-7.5 p-1 sm:p-1 xl:p-1">
                        <div className="mt-6 grow sm:mt-8 lg:mt-0">
                            <div className="space-y-4 rounded-lg border border-gray-100 p-6 ">
                                <div className="space-y-2">
                                    <dl className="flex items-center justify-between gap-4">
                                        <dt className="text-base font-medium ">Original price</dt>
                                        <dd className="text-base font-medium ">₹ {plan.actual_price}</dd>
                                    </dl>

                                    <dl className="flex items-center justify-between gap-4">
                                        <dt className="text-base font-medium ">Savings</dt>
                                        <dd className="text-base font-medium text-green-500">-₹ {plan.actual_price - plan.price}</dd>
                                    </dl>

                                    {/* <dl className="flex items-center justify-between gap-4">
                                        <dt className="text-base font-medium ">Processing Fee</dt>
                                        <dd className="text-base font-medium ">+ ₹ {plan.validMonths * 2}</dd>
                                    </dl> */}
                                </div>

                                <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2">
                                    <dt className="text-base font-bold ">Total</dt>
                                    <dd className="text-base font-bold ">₹ {totalPrice}</dd>
                                </dl>
                            </div>
                            <button onClick={handleOrder}
                                className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white">
                                Place Order
                            </button>


                        </div>
                    </div>
                </div >

            </div>
        </>
    )

}
export default CheckoutPage;