import axios from "axios";

const url = "https://quickfill-server.netlify.app/.netlify/functions/api";



export const redirectToCheckout = async (order_payload: any) => {
  try {
    const response = await axios.post(`${url}/redirectToCheckout`,order_payload);
    return response.data.paymentSessionID
  } catch (error) {
    console.log("Error while saving user", error);
  }
};
