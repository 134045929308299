import React from 'react';
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb';
import QuickFillImage from '../common/QuickFillImage';
import QuickFillDesc from '../common/QuickFillDesc';
import { autoprofileCreationImages, autoProfileCreationPoints, autoprofileCreationShortcut } from '../constants/constants';


const AutoProfileCreation = () => {
    return (
        <>
            <Breadcrumb pageName="AutoProfileCreation" />

            {/* <!-- ====== FileFill Section Start ====== --> */}
            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <QuickFillImage quickFillImages={autoprofileCreationImages}/>

                <QuickFillDesc quickFillPoints={autoProfileCreationPoints} headerText="Auto Profile Creation" quickFillShortcut={autoprofileCreationShortcut}/>

            </div>


            {/* <!-- ====== FileFill Section End ====== --> */}
        </>
    );
};

export default AutoProfileCreation;
