import React, { useState } from 'react';

interface QuickFillImageProps {
  quickFillImages: { id: number; src: any; alt: string }[];
}

const QuickFillImage: React.FC<QuickFillImageProps> = ({ quickFillImages }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false); // Track zoom state

  const handlePrev = () => {
    setActiveSlide((prev) => (prev - 1 + quickFillImages.length) % quickFillImages.length);
  };

  const handleNext = () => {
    setActiveSlide((prev) => (prev + 1) % quickFillImages.length);
  };

  const goToSlide = (index: number) => {
    setActiveSlide(index);
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div className="col-span-12 h-fit rounded-sm border border-stroke bg-white p-2 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-5">
      <div className="relative rounded-lg flex flex-col items-center gap-2 w-full h-full">
        {quickFillImages.map((slide, index) => (
          <div
            key={slide.id}
            className={`flex items-center justify-center h-20 3xsm:h-28 2xsm:h-40 xsm:h-56 lg:h-[21rem] md:h-80 sm:h-64 inset-0 transition-opacity duration-700 ${
              activeSlide === index ? 'opacity-100' : 'opacity-0 hidden'
            }`}
          >
            <img
              src={slide.src}
              className="block max-h-20 3xsm:max-h-28 2xsm:max-h-40 xsm:max-h-56 lg:max-h-[21rem] md:max-h-80 sm:max-h-64 cursor-pointer"
              alt={slide.alt}
              onClick={toggleZoom} // Enable zoom on click
            />
          </div>
        ))}
        <div className="z-30 flex space-x-3 rtl:space-x-reverse">
          {quickFillImages.map((_, index) => (
            <button
              key={index}
              type="button"
              className={`w-3 h-3 rounded-full ${
                activeSlide === index ? 'bg-blue-600' : 'bg-gray-400'
              }`}
              aria-current={activeSlide === index}
              aria-label={`Slide ${index + 1}`}
              onClick={() => goToSlide(index)}
            ></button>
          ))}
        </div>
      </div>

      <button
        type="button"
        className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={handleNext}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg
            className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>

      {/* Zoomed Image Modal */}
      {isZoomed && (
        <div
          className="fixed inset-0 z-[10000] flex items-center justify-center bg-black bg-opacity-50"
          onClick={toggleZoom} // Close zoom on clicking the overlay
        >
          <div
            className="relative max-w-[90%] max-h-[90%] bg-white rounded-lg shadow-lg p-4"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <img
              src={quickFillImages[activeSlide].src}
              alt={quickFillImages[activeSlide].alt}
              className="max-w-full max-h-full object-contain"
            />
            <button
              className="absolute top-2 right-2 text-gray-700 bg-gray-200 rounded-full p-1 hover:bg-gray-300 focus:outline-none"
              onClick={toggleZoom}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuickFillImage;
