import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';


interface HomeDescState {

}

const HomeDesc: React.FC = () => {

    return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-6">
            <div className="flex flex-col gap-7.5 p-1 sm:p-1 xl:p-1">
                <div>
                    <h3 className="mb-5 text-title-md2 font-bold text-black dark:text-white">WHY theQUICKFILL?🤔</h3>
                    <p className="font-medium">
                        Say goodbye to tedious form filling! Our advanced AutoFill feature detects form fields and fills them for you with a single click. Here’s how it works:
                    </p>
                    <ul className="list-disc flex flex-col space-y-2 pl-4 py-4">
                        <li className="font-medium">
                            Have you ever been bogged down by those never-ending, monotonous forms that seem to go on forever? 😩
                        </li>
                        <li className="font-medium">
                            Imagine having to repeatedly input the same information day in and day out! 😫
                        </li>
                        <li className="font-medium">
                            That's where we swoop in to save the day! 🦸‍♂️ With theQuickFill, you just need to enter your information once, and the next time you visit any website, boom! 🎉
                        </li>
                        <li className="font-medium">
                            Fill up those forms in a flash with just one click! 💥 No more hassle, no more repetitive typing. 😎
                        </li>
                        <li className="font-medium">
                            And guess what? We don't save your info; it stays right on your local device, safe and sound. 🔒 Your privacy? Consider it our top priority! 👍
                        </li>

                    </ul>
                    <h2 className="mb-5 text-title-sm font-extrabold dark:text-white">WHERE TO USE?🤔</h2>
                    <div className='flex flex-wrap gap-2'>
                        <div className='class="flex flex-wrap"'>
                            <p className='inline-flex items-center justify-center rounded-full bg-black py-1 px-4 lg:px-4 xl:px-4 text-center font-medium text-white hover:bg-opacity-90 '>
                                Placement Forms
                            </p>
                        </div>
                        <div className='class="flex flex-wrap"'>
                            <p className='inline-flex items-center justify-center rounded-full bg-black py-1 px-4 lg:px-4 xl:px-4 text-center font-medium text-white hover:bg-opacity-90 '>
                                BSE/NSE Forms
                            </p>
                        </div>
                        <div className='class="flex flex-wrap"'>
                            <p className='inline-flex items-center justify-center rounded-full bg-black py-1 px-4 lg:px-4 xl:px-4 text-center font-medium text-white hover:bg-opacity-90 '>
                                Quick Login/Register
                            </p>
                        </div>
                        <div className='class="flex flex-wrap"'>
                            <p className='inline-flex items-center justify-center rounded-full bg-black py-1 px-4 lg:px-4 xl:px-4 text-center font-medium text-white hover:bg-opacity-90 '>
                                Logistic companies
                            </p>
                        </div>
                        <div className='class="flex flex-wrap"'>
                            <p className='inline-flex items-center justify-center rounded-full bg-black py-1 px-4 lg:px-4 xl:px-4 text-center font-medium text-white hover:bg-opacity-90 '>
                                Testers/ Form Testing
                            </p>
                        </div>
                        
                        
                    </div>


                </div>
            </div>
        </div >
    );
};

export default HomeDesc;
