import React, { useState } from 'react';
import QuickFillHome from '../../images/product/autofill1.png';
import QucikFillHome2 from '../../images/product/demo_image2.png';


const HomeImage: React.FC = () => {
    const [activeSlide, setActiveSlide] = useState(0);

    const slides = [
        { id: 0, src: QuickFillHome, alt: 'Slide 1' },
        { id: 1, src: QucikFillHome2, alt: 'Slide 2' },
        { id: 2, src: QuickFillHome, alt: 'Slide 3' },
        { id: 3, src: QuickFillHome, alt: 'Slide 4' },
        { id: 4, src: QuickFillHome, alt: 'Slide 5' },
    ];

    const handlePrev = () => {
        setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    const handleNext = () => {
        setActiveSlide((prev) => (prev + 1) % slides.length);
    };

    const goToSlide = (index: number) => {
        setActiveSlide(index);
    };

    return (
        <div className="col-span-12 h-fit rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">
            <div className=" h-fit rounded-lg flex flex-col items-center gap-2">
                <iframe className="w-full h-96" src="https://www.youtube.com/embed/2DncJp5Pxio" title="QuickFill" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                {/* <img
                            src={slide.src}
                            className=" block w-full lg:h-[21rem] md:h-80 sm:h-64"
                            alt={slide.alt}
                        /> */}


            </div>


        </div>
    );
};

export default HomeImage;
