import React from 'react';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import { showPasswordPoints, showPasswordShortcut } from './constants/constants';

const ShowPassword = () => {
    return (
        <>
            <Breadcrumb pageName="ShowPassword" />

            {/* <!-- ====== ShowPassword Section Start ====== --> */}
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="flex flex-col gap-7.5 p-4 sm:p-6 xl:p-9">
                    <div>
                        <ul className="list-disc flex flex-col space-y-2 pl-4 py-4">
                            {showPasswordPoints.map((item, index) => (
                                <li className="font-medium">
                                    {item}
                                </li>
                            ))}

                            <p className="font-extrabold pb-4">
                                {"Shortcuts(Else right click go in QuickFill->Show Password):"}
                            </p>
                            <div className="bg-black rounded-lg p-4 w-fit text-white">
                                <p>
                                    Windows: <span className="font-bold">{showPasswordShortcut['windows']}</span>

                                </p>
                                <p>
                                    Mac: <span className="font-bold">{showPasswordShortcut['mac']}</span>

                                </p>
                            </div>
                        </ul>
                    </div>


                </div>
            </div>

            {/* <!-- ====== ShowPassword Section End ====== --> */}
        </>
    );
};

export default ShowPassword;
