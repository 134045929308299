import { terms_date } from '../common/Dates';
import React from 'react';

const TermsConditions = () => {
    return (
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="flex flex-col gap-7.5 p-4 sm:p-6 xl:p-9">
                <div>
                    <h3 className="mb-5 text-title-md2 font-bold text-black dark:text-white">
                        Terms and Conditions for theQuickFill ({terms_date})
                    </h3>
                    <p className="font-medium">
                        Welcome to theQuickFill! By using our Chrome Extension (“Extension”),
                        you agree to abide by the following terms and conditions. If you do not agree,
                        please refrain from using theQuickFill.
                    </p>
                </div>

                {/* Section 1 */}
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        1. Use of the Extension
                    </h4>
                    <p className="font-medium">
                        <strong>1.1 Purpose:</strong> theQuickFill is designed to simplify form filling by offering
                        AutoFill, FileFill, and Advanced AutoFill functionalities.
                    </p>
                </div>

                {/* Section 2 */}
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        2. Features and Functionalities
                    </h4>
                    <p className="font-medium">
                        theQuickFill does not collect, store, or transmit any personally identifiable
                        information or sensitive data. The Extension operates solely within the user's
                        browser environment and does not communicate with external servers except for
                        storing email addresses and full names detected automatically.
                    </p>

                    <ul className="list-disc list-inside font-medium">
                        <li>
                            <strong>AutoFill:</strong> Detects form fields and fills them based on your provided key-value pairs.
                            <ul className="list-disc list-inside pl-5">
                                <li>Synonyms and related terms are automatically detected (e.g., "email" matches "email address").</li>
                                <li>Shortcuts:
                                    <ul className="list-disc list-inside pl-5">
                                        <li><strong>Windows:</strong> CTRL + SHIFT + L</li>
                                        <li><strong>Mac:</strong> CMD + SHIFT + L</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>FileFill:</strong> Upload and assign key names to important files for quick access during form-filling.
                            <ul className="list-disc list-inside pl-5">
                                <li>Files remain securely stored on your local device; theQuickFill does not access or transmit these files.</li>
                                <li>Shortcuts:
                                    <ul className="list-disc list-inside pl-5">
                                        <li><strong>Windows:</strong> CTRL + SHIFT + L</li>
                                        <li><strong>Mac:</strong> CMD + SHIFT + L</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Advanced AutoFill:</strong> Manage multiple profiles for different clients to auto-fill their details seamlessly.
                            <ul className="list-disc list-inside pl-5">
                                <li>Profiles are stored locally to ensure data security.</li>
                                <li>Shortcuts:
                                    <ul className="list-disc list-inside pl-5">
                                        <li><strong>Windows:</strong> CTRL + SHIFT + H</li>
                                        <li><strong>Mac:</strong> CMD + SHIFT + H</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                {/* Section 3 */}
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        3. User Responsibilities
                    </h4>
                    <p className="font-medium">
                        <strong>3.1 Accuracy of Data:</strong> You are responsible for ensuring the accuracy of data and files entered into the Extension.
                    </p>
                    <p className="font-medium">
                        <strong>3.2 Prohibited Use:</strong> You agree not to misuse theQuickFill for illegal or unauthorized purposes.
                    </p>
                </div>

                {/* Section 4 */}
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        4. Privacy and Data Security
                    </h4>
                    <p className="font-medium">
                        <strong>4.1 Data Storage:</strong> theQuickFill does not store or share any data externally, except for email addresses required for subscription purposes.
                    </p>
                    <p className="font-medium">
                        <strong>4.2 Local Storage:</strong> All data, including profiles and files, is securely stored on your local device.
                    </p>
                    <p className="font-medium">
                        <strong>4.3 Privacy Policy:</strong> Refer to our Privacy Policy for detailed information on how we handle data.
                    </p>
                </div>

                {/* Section 5 */}
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        5. Limitation of Liability
                    </h4>
                    <p className="font-medium">
                        <strong>5.1 No Warranties:</strong> theQuickFill is provided “as-is” without warranties of any kind.
                    </p>
                    <p className="font-medium">
                        <strong>5.2 Exclusions:</strong> We are not liable for any damages arising from the use or inability to use the Extension, including data loss, performance issues, or unauthorized access to local files.
                    </p>
                </div>

                {/* Section 6-10 */}
                {[
                    {
                        title: '6. Intellectual Property',
                        content: [
                            'All rights, titles, and interests in theQuickFill, including trademarks, logos, and content, belong to theQuickFill and are protected by copyright laws.',
                            'You may not copy, modify, distribute, or reverse-engineer any part of theQuickFill.',
                        ],
                    },
                    {
                        title: '7. Updates and Changes',
                        content: [
                            'theQuickFill reserves the right to update or modify these terms at any time. Changes will be effective immediately upon posting.',
                            'Continued use of the Extension after updates signifies your acceptance of the revised terms.',
                        ],
                    },
                    {
                        title: '8. Termination',
                        content: ['theQuickFill reserves the right to suspend or terminate access to the Extension for violations of these terms.'],
                    },
                    {
                        title: '9. Governing Law',
                        content: ['These Terms are governed by and construed in accordance with the laws of your country of residence.'],
                    },
                    {
                        title: '10. Contact Information',
                        content: ['For questions or concerns regarding these Terms and Conditions, contact us at neelmehta@thequickfill.com.'],
                    },
                ].map((section, index) => (
                    <div key={index}>
                        <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">{section.title}</h4>
                        {section.content.map((text, i) => (
                            <p className="font-medium" key={i}>
                                {text}
                            </p>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TermsConditions;
