import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';


interface QuickFillDescState {
    quickFillPoints: string[]
    headerText: string;
    quickFillShortcut: { [key: string]: string }
}

const QuickFillDesc: React.FC<QuickFillDescState> = ({ quickFillPoints, headerText, quickFillShortcut }) => {
    console.log(quickFillShortcut)
    return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-7">
            <div className="flex flex-col gap-7.5 p-1 sm:p-1 xl:p-1">
                <div>
                    <h3 className="mb-5 text-title-md2 font-bold text-black dark:text-white">{headerText} 🚀</h3>
                    <p className="font-medium">
                        Say goodbye to tedious form filling! Our advanced AutoFill feature detects form fields and fills them for you with a single click. Here’s how it works:
                    </p>
                    <ul className="flex flex-col space-y-2 pl-4 py-4">
                        {quickFillPoints.map((item, index) => (
                            <li key={index} className="font-medium">
                                {item}
                            </li>
                        ))}
                    </ul>
                    <p className="font-extrabold pb-4">
                        {"Shortcuts(Else right click go in QuickFill->AutoFill):"}
                    </p>
                    <div className="bg-black rounded-lg p-4 w-fit text-white">
                        <p>
                            Windows: <span className="font-bold">{quickFillShortcut["windows"]}</span>

                        </p>
                        <p>
                            Mac: <span className="font-bold">{quickFillShortcut["mac"]}</span>
                        </p>
                    </div>

                </div>
            </div>
        </div >
    );
};

export default QuickFillDesc;
