export const pricingPlans = [
    // {
    //     title: "Starter",
    //     price: "69",
    //     actual_price: "100",
    //     validity: "Valid till a month",
    //     features: [
    //         "Individual configuration",
    //         "No setup, or hidden fees",
    //         {
    //             text: "Access to",
    //             products: [
    //                 { name: "AutoFill", link: "/products/autofill" },
    //             ],
    //         },
    //         "Data: unlimited entries per profile",
    //     ],
    //     validMonths: '1',
    //     pricingKey: "starter",
    //     isBestValue: false,
    //     isAutoFill: "true",
    //     isFileFill: "false",
    //     isProfiles: "false"
    // },
    // {
    //     title: "Plus",
    //     price: "89",
    //     actual_price: "120",
    //     validity: "Valid till a month",
    //     features: [
    //         "Individual configuration",
    //         "No setup, or hidden fees",
    //         {
    //             text: "Access to",
    //             products: [
    //                 { name: "AutoFill", link: "/products/autofill" },
    //                 { name: "FileFill", link: "/products/filefill" },
    //             ],
    //         },
    //         "Data: unlimited entries per profile",
    //     ],
    //     validMonths: '1',
    //     pricingKey: "plus",
    //     isBestValue: false,
    //     isAutoFill: "true",
    //     isFileFill: "true",
    //     isProfiles: "false"
    // },
    {
        title: "Starter",
        price: "99",
        actual_price: "150",
        validity: "Valid till a month",
        features: [
            "Individual configuration",
            "No setup, or hidden fees",
            {
                text: "Access to",
                products: [
                    { name: "AutoFill", link: "/products/autofill" },
                    { name: "FileFill", link: "/products/filefill" },
                    { name: "Profiles(Advance AutoFill)", link: "/products/profiles" },
                ],
            },
            "Data: unlimited entries per profile",
        ],
        validMonths: '1',
        pricingKey: "starter",
        isBestValue: false,
        isAutoFill: "true",
        isFileFill: "true",
        isProfiles: "true"
    },
    {
        title: "Pro",
        price: "549",
        actual_price: "900",
        validity: "Valid till 6months",
        features: [
            "Individual configuration",
            "No setup, or hidden fees",
            {
                text: "Access to",
                products: [
                    { name: "AutoFill", link: "/products/autofill" },
                    { name: "FileFill", link: "/products/filefill" },
                    { name: "Profiles(Advance AutoFill)", link: "/products/profiles" },
                ],
            },
            "Data: unlimited entries per profile",
        ],
        validMonths: '6',
        pricingKey: "pro",
        isBestValue: false,
        isAutoFill: "true",
        isFileFill: "true",
        isProfiles: "true"
    },
    {
        title: "Elite",
        price: "999",
        actual_price: "1800",
        validity: "Valid till a year",
        features: [
            "Individual configuration",
            "No setup, or hidden fees",
            {
                text: "Access to",
                products: [
                    { name: "AutoFill", link: "/products/autofill" },
                    { name: "FileFill", link: "/products/filefill" },
                    { name: "Profiles(Advance AutoFill)", link: "/products/profiles" },
                ],
            },
            "Data: unlimited entries per profile",
        ],
        validMonths: '12',
        pricingKey: "elite",
        isBestValue: true,
        isAutoFill: "true",
        isFileFill: "true",
        isProfiles: "true"
    },
    {
        title: "Enterprise",
        price: "",
        actual_price: "",
        validity: "",
        features: [
            "Individual configuration",
            "No setup, or hidden fees",
            {
                text: "Access to",
                products: [
                    { name: "AutoFill", link: "/products/autofill" },
                    { name: "FileFill", link: "/products/filefill" },
                    { name: "Profiles(Advance AutoFill)", link: "/products/profiles" },
                ],
            },
            "Data: unlimited entries per profile",
        ],
        validMonths: '',
        pricingKey: "enterprise",
        isBestValue: false,
        isAutoFill: "true",
        isFileFill: "true",
        isProfiles: "true"
    },

];