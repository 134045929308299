import { privacy_date } from '../common/Dates'
import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="flex flex-col gap-7.5 p-4 sm:p-6 xl:p-9">
                <div>
                    <h3 className="mb-5 text-title-md2 font-bold text-black dark:text-white">
                        Privacy Policy ({privacy_date})
                    </h3>
                    <p className="font-medium">
                        This Privacy Policy governs the manner in which theQuickFill Chrome
                        Extension ("Extension") stores and handles information collected from
                        users (each, a "User"). This privacy policy applies to the Extension and
                        all products and services offered by theQuickFill.
                    </p>
                </div>
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        Information Storage
                    </h4>
                    <p className="font-medium">
                        We store email addresses solely for subscription purposes, and we do not
                        sell or share this information with anyone. All data generated or
                        provided by the user within the Extension is stored locally on the
                        user's device using Google Local Storage.
                    </p>
                </div>
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        Data Collection
                    </h4>
                    <p className="font-medium">
                        We do not collect, store, or transmit any personally identifiable
                        information or sensitive data from users. The Extension operates solely
                        within the user's browser environment and does not communicate with
                        external servers except for the storing of email & Full Name which we detect
                        automatically.
                    </p>

                </div>
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">Web browser cookies</h4>

                    <p className="font-medium">
                        Our Extension does not use "cookies" to enhance User experience.
                    </p>

                </div>

                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        Changes to this privacy policy
                    </h4>
                    <p className="font-medium">
                        TheQuickFill has the discretion to update this privacy policy at any
                        time. When we do, we will revise the updated date at the top of this
                        page. We encourage Users to frequently check this page for any changes
                        to stay informed about how we are productsing to protect the personal
                        information we collect. You acknowledge and agree that it is your
                        responsibility to review this privacy policy periodically and become
                        aware of modifications.
                    </p>

                </div>
                <div>
                    <h4 className="mb-5 text-title-md font-bold leading-[30px] text-black dark:text-white">
                        Your acceptance of these terms
                    </h4>
                    <p className="font-medium">
                        By using this Extension, you signify your acceptance of this policy. If
                        you do not agree to this policy, please do not use our Extension. Your
                        continued use of the Extension following the posting of changes to this
                        policy will be deemed your acceptance of those changes.
                    </p>

                </div>
                {/* <div>
                    <h4 className="mb-5 text-title-sm2 font-bold leading-[30px] text-black dark:text-white">
                        Your acceptance of these terms
                    </h4>
                    <ul className="flex flex-col gap-2.5">
                        <li className="font-medium text-primary underline">Lorem ipsum dolor sit amet,</li>
                        <li className="font-medium text-primary underline">Curabitur lorem sem scelerisque erat volutpat.</li>
                        <li className="font-medium text-primary underline">Scelerisque erat volutpat.</li>
                        <li className="font-medium text-primary underline">elementum eleifend</li>
                    </ul>
                </div> */}
            </div>
        </div >

    )
}

export default PrivacyPolicy
