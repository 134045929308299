import { useEffect, useState } from 'react';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Loader from './common/Loader/Loader';
import PageTitle from './components/PageTitle';
import DefaultLayout from './layout/DefaultLayout';
import AutoFill from './pages/Products/AutoFill/AutoFill';
import ShowPassword from './pages/Products/ShowPassword';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home/Home';
import FileFill from './pages/Products/FileFill/FileFill';
import Profiles from './pages/Products/Profiles/Profiles';
import Pricing from './pages/Pricing/Pricing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import CheckoutPage from './pages/Pricing/CheckoutPage';
import ContactUs from './pages/ContactUs';
import AutoProfileCreation from './pages/Products/AutoProfileCreation/AutoProfileCreation';
import ViewProfile from './pages/Products/ViewProfile/ViewProfile';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <DefaultLayout>
      <Routes>
        <Route
          index
          element={
            <>
              <PageTitle title="Home" />
              <Home />
            </>
          }
        />
        <Route
          path='/products/autofill'
          element={
            <>
              <PageTitle title="AutoFill" />
              <AutoFill />
            </>
          }
        />
        <Route
          path='/products/filefill'
          element={
            <>
              <PageTitle title="FileFill" />
              <FileFill />
            </>
          }
        />
        <Route
          path='/products/profiles'
          element={
            <>
              <PageTitle title="Profiles" />
              <Profiles />
            </>
          }
        />
        <Route
          path='/products/autoprofilecreation'
          element={
            <>
              <PageTitle title="Auto Profile Creation" />
              <AutoProfileCreation />
            </>
          }
        />
        <Route
          path='/products/viewProfile'
          element={
            <>
              <PageTitle title="View Profile" />
              <ViewProfile />
            </>
          }
        />
        <Route
          path='/products/showpassword'
          element={
            <>
              <PageTitle title="Show password" />
              <ShowPassword />
            </>
          }
        />
        <Route
          path="/pricing"
          element={
            <>
              <PageTitle title="Pricing" />
              <Pricing />
            </>
          }
        />
        <Route
          path="/pricing/checkout"
          element={
            <>
              <PageTitle title="Checkout" />
              <CheckoutPage />
            </>
          }
        />
        <Route
          path="/contactUs"
          element={
            <>
              <PageTitle title="ContactUs" />
              <ContactUs />
            </>
          }
        />
        <Route
          path="/privacy_policy"
          element={
            <>
              <PageTitle title="Privacy Policy" />
              <PrivacyPolicy />
            </>
          }
        />
        <Route
          path="/terms_conditions"
          element={
            <>
              <PageTitle title="Privacy Policy" />
              <TermsConditions />
            </>
          }
        />


        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </DefaultLayout>
  );
}

export default App;
